/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import "./instrument";
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { history } from './utils/history';
import './libraries/fontawesome';
import './sass/app.scss';
import AdminRoutes from './routes/AdminRoutes';
import LearnerRoutes from './routes/LearnerRoutes';
import TrainerRoutes from './routes/TrainerRoutes';
import ProtectedRoute from './components/Layout/ProtectedRoute';
import { Redirect, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import apiDocumentation from './components/apiDocumentation/apiDocumentation';
import Loader from './components/Shared/Loader/Loader';
import { getResourceRealPath, retrieveDecryptedSiteFromLocalStorage, retrieveDecryptedUserFromLocalStorage} from './utils/common';
import { PackageVersion } from './constants/apiConstants';
import { useSelector } from 'react-redux';
import './utils'
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const WishlistPage = React.lazy(() => import('./pages/WishlistPage'));
const ForgotPasswordPage = React.lazy(() =>
    import('./pages/ForgotPasswordPage'),
);
const ResetPasswordPage = React.lazy(() =>
    import('./pages/ResetPasswordPage'),
);
const ResetPasswordV2Page = React.lazy(() =>
    import('./pages/ResetPasswordV2Page'),
);
const FirstLoginPage = React.lazy(() => import('./pages/FirstLoginPage'));
const SearchPage = React.lazy(() => import('./pages/SearchPage'));
const LibraryPage = React.lazy(() => import('./pages/LibraryPage'));
const ActivityPage = React.lazy(() => import('./pages/ActivityPage'));
const LearnerModulePage = React.lazy(() => import('./pages/Learner/ModulePage'));
const LogInPage = React.lazy(() => import('./pages/LoginPage'));
const NotificationPage = React.lazy(() => import('./pages/NotificationPage'));
const ModulePage = React.lazy(() => import('./pages/ModulePage'));
const SettingsPage = React.lazy(() => import('./pages/SettingsPage'));
const ProfilePage = React.lazy(() => import('./pages/ProfilePage'));
const ColourPicker = React.lazy(() => import('./pages/ColourPicker'));
const EmptyPage = React.lazy(() => import('./pages/Empty'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const AuthSSO = React.lazy(() => import('./pages/AuthSSO'))
const AuthSSOFailure = React.lazy(() => import('./pages/AuthSSOFailure'))
const MaintenancePage = React.lazy(() => import('./pages/MaintenancePage'));
const FAQPage = React.lazy(() => import('./pages/FAQPage'));


const App = () => {
    const [userRole, setUserRole] = useState(null);
    const currentUser = useSelector(state => state.authentication.user);
    useEffect(() => {
        if (currentUser) {
            setUserRole(currentUser.role);
        }
    }, [currentUser]);

    const siteInfo = retrieveDecryptedSiteFromLocalStorage();
    const userLoggedIn = retrieveDecryptedUserFromLocalStorage() !== null;
    const permissions = userLoggedIn
        ? retrieveDecryptedUserFromLocalStorage().permissions
        : [];
    const permissionArray = permissions
        ? permissions?.map(permission => permission?.name)
        : [];
    if (siteInfo?.primaryColor) {
        document.body.style.setProperty(
            '--primary-color',
            siteInfo?.primaryColor,
        );
    }

    if (window.siteInfo?.secondaryColor) {
        document.body.style.setProperty(
            '--secondary-color',
            window.siteInfo?.secondaryColor,
        );
    }

    document.body.style.setProperty(
        '--antdesign-default-color',
        siteInfo?.primaryColor,
    );
    document.body.style.setProperty('font-family', window.siteInfo?.font);
    document.body.style.setProperty(
        '--antdesign-primary-color',
        siteInfo?.primaryColor,
    );
    document.body.style.setProperty(
        '--antdesign-hover-color',
        siteInfo?.primaryColor,
    );

    // change font family
    let fontPath = window.siteInfo?.font;
    if (fontPath) {
        let fontArr = fontPath?.split('/');
        let fontName = fontArr[fontArr.length - 1]?.split('.')[0];

        let new_font = new FontFace(
            fontName,
            `URL(${window?.siteInfo?.font}) format("truetype")`,
        );
        new_font
            .load()
            .then(function(loaded_face) {
                document.fonts.add(loaded_face);
                document.body.style.setProperty('font-family', fontName);
            })
            .catch(function(error) {});
    }


    // usePageViews();

       return (
        <BrowserRouter history={history}>
                <Helmet>
                    <title>{siteInfo?.title || ''} </title>
                    <link rel="shortcut icon" type="image/jpg" href={getResourceRealPath(siteInfo?.favIcon)} />
                </Helmet>
                <Suspense fallback={
                    <div
                        style={{
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                    }}>
                        <Loader />
                    </div>
                }>
                    <Switch>
                        <Route component={MaintenancePage} key="/maintenance" path="/maintenance"/>
                        <Route path="/login" component={LogInPage} />
                        <Route path="/auth-sso" component={AuthSSO} />
                        <Route path="/auth-sso-failure" component={AuthSSOFailure} />
                        {/* {userLoggedIn && user.role !== 'learner' ? (
                                <Redirect to="/dashboard" />
                            ) : userLoggedIn && user.role === 'learner' ? (
                                <Redirect to="/learner/dashboard" />
                            ) : (
                                <LogInPage />
                            )} */}
                        {/* </Route> */}
                        <Route path="/api" component={apiDocumentation} />
                        {!userLoggedIn && (
                            <Route
                                path="/forgot-password"
                                component={ForgotPasswordPage}
                            />
                        )}
                        {!userLoggedIn && (
                            <Route
                                path="/reset-password"
                                component={ResetPasswordPage}
                            />
                        )}
                        {!userLoggedIn && (
                            <Route
                                path="/auth/reset-password/:token"
                                component={ResetPasswordV2Page}
                            />
                        )}
                        <ProtectedRoute
                            path="/empty"
                            component={EmptyPage}
                            key="empty"
                        />
                        <ProtectedRoute
                            key="/FAQ"
                            path="/FAQ"
                            component={FAQPage}
                            history={history}
                        />
                        <ProtectedRoute
                            path="/dashboard"
                            component={DashboardPage}
                            key="dashboard"
                        />
                        <ProtectedRoute
                            path="/firstlogin"
                            component={FirstLoginPage}
                            key="firstlogin"
                        />
                        <ProtectedRoute
                            key="/module/:moduleID"
                            path="/module/:moduleID"
                            component={ModulePage}
                            history={history}
                        />
                        <ProtectedRoute
                            key="/notifications"
                            path="/notifications"
                            component={NotificationPage}
                        />
                        <ProtectedRoute
                            path="/settings"
                            component={SettingsPage}
                            key="/settings"
                        />
                        <ProtectedRoute
                            path="/profile"
                            component={ProfilePage}
                            key="/profile"
                        />
                        <ProtectedRoute
                            path="/search"
                            component={SearchPage}
                            key="/search"
                        />
                        <ProtectedRoute
                            key="/colour-picker"
                            path="/colour-picker"
                            component={ColourPicker}
                        />
                        <ProtectedRoute
                            key="/wishlist"
                            path="/wishlist"
                            component={WishlistPage}
                            history={history}
                        />
                        <ProtectedRoute
                            key="/library"
                            path="/library"
                            component={LibraryPage}
                            history={history}
                        />
                        <ProtectedRoute
                            path="/admin/modules/:moduleID/activities"
                            component={LearnerModulePage}
                            key="/modulePage"
                        />
                        <ProtectedRoute
                            path="/activity/:activityID/module/:moduleID"
                            component={ActivityPage}
                            key="/activitywithid"
                        />
                        <ProtectedRoute
                            path="/activity"
                            component={ActivityPage}
                            key="/activity"
                        />
                        {!(userRole === 'learner')
                            ? AdminRoutes.map(r => r)
                            : LearnerRoutes.map(r => r)}

                        {TrainerRoutes.map(r => r)}
                        {/* <Route>
                            {user.role === 'learner'
                                ? LearnerRoutes.map(r => r)
                                : 'Not Authorized'}
                        </Route> */}
                        <ProtectedRoute
                            path="/"
                            component={DashboardPage}
                            key="homepage"
                        />
                        <ProtectedRoute
                            key="/courseBundleID/:courseBundleID/courseID/:courseID/moduleID/:moduleID"
                            path="/courseBundleID/:courseBundleID/courseID/:courseID/moduleID/:moduleID"
                            component={ModulePage}
                            history={history}
                        />
                        <ProtectedRoute
                            key="/courseID/:courseID/moduleID/:moduleID"
                            path="/courseID/:courseID/moduleID/:moduleID"
                            component={ModulePage}
                            history={history}
                        />
                        <ProtectedRoute
                            key="/learningPathwayID/:learningPathwayID/learningPathwaySectionID/:learningPathwaySectionID/moduleID/:moduleID"
                            path="/learningPathwayID/:learningPathwayID/learningPathwaySectionID/:learningPathwaySectionID/moduleID/:moduleID"
                            component={ModulePage}
                            history={history}
                        />
                        <ProtectedRoute
                            key="/learningPathwayID/:learningPathwayID/moduleID/:moduleID"
                            path="/learningPathwayID/:learningPathwayID/moduleID/:moduleID"
                            component={ModulePage}
                            history={history}
                        />
                        <Route component={NotFound} key="notfound" />
                    </Switch>
                </Suspense>
        </BrowserRouter>
    );
};
// const App = () => {
//     return (
//         <Router history={history}>
//             <SuspenseRoutes />
//         </Router>
//     );
// };
export default App;
