import rolesAndPermisions from '../../../redux/constants/rolesAndPermissions.constants';

const getLinks = user => {
    const isAdmin = user.role === 'administrator' ? true : false;

    const canAccessUsersReports =
        user?.permissions.findIndex(
            x =>
                x.code ===
                rolesAndPermisions.accessReportingDataForAllLinkedUsersUserGroups,
        ) !== -1;
    const canAccessAllUsersReports =
        user?.permissions.findIndex(
            x =>
                x.code ===
                rolesAndPermisions.accessReportingDataForAllUsersUserGroupsOnSite,
        ) !== -1;
    const canAccessUsersReportsBetweenArrivals =
        user?.permissions.findIndex(
            x =>
                x.code ===
                rolesAndPermisions.accessReportingDataForAllLinkedUsersBetweenStartArrivalDateAndDepartureDatePlusOneWeek,
        ) !== -1;

    const canAllocateContent = user?.permissions.find(
        x => x.code == rolesAndPermisions.allocateContent,
    );

    const canCreateContent = user?.permissions.find(
        x => x.code == rolesAndPermisions.createContentForTheSite,
    );

    const canCreatePathway = user.permissions.find(
        x => x.code == rolesAndPermisions.createHybridLearningPathway,
    );

    const canCreateOrCopyOrDeleteLibraryBundle = user.permissions.find(
        x => x.code == rolesAndPermisions.createCopyEditAndDeleteALibraryBundle,
    );

    const createCopyEditAndDeleteASiteAuthoredLibraryBundleAndMakeAvailableOnTheirSite = user.permissions.find(
        x => x.code == rolesAndPermisions.createCopyEditAndDeleteASiteAuthoredLibraryBundleAndMakeAvailableOnTheirSite,
    );

    const canSeeAllAllocatedContetnToLinkedUsers = user.permissions.find(
        x =>
            x.code ==
            rolesAndPermisions.seeAllContentAllocatedToLinkedUsersUserGroups,
    );

    const canSeeAllModulesAvailableOnSite = user.permissions.find(
        x =>
            x.code ==
            rolesAndPermisions.seeAllContentAvailableOnSite,
    );

    const canSeeUsers = user?.permissions.find(
        x => x.code == rolesAndPermisions.seeUsers,
    );
    const canSetupAdminUsers = user?.permissions.find(
        x => x.code == rolesAndPermisions.setUpAdminUsers,
    );
    const canSetupLearnersTrainersAndHrmanagerManualCsv = user?.permissions.find(
        x =>
            x.code ==
            rolesAndPermisions.setUpLearnersTrainersHrmanagersUsersManualViaCsv,
    );
    const canSeeeUserGroups = user?.permissions.find(
        x => x.code == rolesAndPermisions.seeUserGroups,
    );
    const canSeeAllUserGroupsOnTheSite = user?.permissions.find(
        x => x.code == rolesAndPermisions.seeAllUserGroupsOnTheSite,
    );
    const canSetUpUserGroups = user?.permissions.find(
        x => x.code == rolesAndPermisions.setUpUserGroupsManual,
    );
    const canSetupTrainers = user?.permissions.find(
        x => x.code === rolesAndPermisions.setUpTrainersManualViaCsv
    )

    const usersLinks = [];

    canSeeUsers &&
        usersLinks.push({
            url: '/admin/users',
            icon: ['fal', 'users'],
            label: window.__lang ? window.__lang('list_users'): 'List Users',
        });

    if (canSetupAdminUsers || canSetupLearnersTrainersAndHrmanagerManualCsv)
        usersLinks.push({
            url: '/admin/users/manage',
            icon: ['fal', 'users'],
            label: window.__lang ? window.__lang('create_user'): 'Create User',
        });

    if (canSeeeUserGroups || canSeeAllUserGroupsOnTheSite)
        usersLinks.push({
            url: '/admin/user-groups',
            icon: ['fal', 'users'],
            label: window.__lang? window.__lang('list_user_groups'): 'List User Groups',
        });
    canSetUpUserGroups &&
        usersLinks.push({
            url: '/admin/user-groups/manage',
            icon: ['fal', 'users'],
            label: window.__lang ? window.__lang('create_user_group'): 'Create User Group',
        });
    canSetupTrainers &&
    usersLinks.push({
        url: '/admin/users/setup-trainer/manage',
        icon: ['fal', 'users'],
        label: window.__lang ? window.__lang('set_up_partenaire_cloe_trainer_account'): 'Set up Partenaire CLOE Trainer account',
    })
    canSetupLearnersTrainersAndHrmanagerManualCsv &&
        usersLinks.push({
            url: '/admin/users/import',
            icon: ['fal', 'users'],
            label: window.__lang ? window.__lang('import_csv'): 'Import CSV',
        });
        
    isAdmin && usersLinks.push({
        url: '/admin/empty-searches-audits',
        label: 'Modules empty searches',
    });
    isAdmin && usersLinks.push({
        url: '/admin/audit-logs',
        label: 'Audits',
    });

    let usersList =
        usersLinks.length > 0
            ? [
                  {
                      icon: ['fal', 'users'],
                      label: window.__lang('users'),
                      items: usersLinks,
                  },
              ]
            : [];

    let trainerContentLinks = [];

    if (canSeeAllModulesAvailableOnSite || canAllocateContent)
        trainerContentLinks.push({
            url: '/admin/ExploreAndAssign',
            label: window.__lang
                ? window.__lang('explore_and_assign')
                : 'Explore And Assign',
            icon: ['fal', 'list-alt'],
        });

    if (
        canCreateContent ||
        canCreateOrCopyOrDeleteLibraryBundle ||
        createCopyEditAndDeleteASiteAuthoredLibraryBundleAndMakeAvailableOnTheirSite ||
        canCreatePathway)
        trainerContentLinks.push({
            url: '/admin/build/assign',
            label: window.__lang
                ? window.__lang('create_and_assign')
                : 'Create And Assign',
            icon: ['fal', 'list-alt'],
        });

    let reportingLinks =
        canAccessUsersReports ||
        canAccessAllUsersReports ||
        canAccessUsersReportsBetweenArrivals
            ? {
                  url: '/admin/report',
                  icon: ['fal', 'chart-pie-alt'],
                  label: window.__lang
                      ? window.__lang('reporting')
                      : 'reporting',
              }
            : '';

    const links = permissions => {
        return {
            trainer: [
                {
                    url: '/dashboard',
                    icon: ['fal', 'list-ul'],
                    label: window.__lang('dashboard'),
                },
                ...usersList,
                {
                    icon: ['fal', 'list-alt'],
                    label: ' Content',
                    items:
                        trainerContentLinks.length == 0
                            ? null
                            : trainerContentLinks,
                    url:
                        trainerContentLinks.length == 0
                            ? '/admin/search'
                            : null,
                },
                ,
                reportingLinks,
                // {
                //     url: '/admin/report',
                //     icon: ['fal', 'chart-pie-alt'],
                //     label: window.__lang('reporting'),
                // },

                {
                    url: '/admin/search',
                    icon: ['fal', 'search'],
                    label: window.__lang('search'),
                },
                {
                    url: '/admin/wishlist',
                    icon: ['fal', 'heart'],
                    label: window.__lang('wishlist'),
                },
                {
                    url: '/admin/learners/saved-content',
                    icon: ['fal', 'clipboard-list-check'],
                    label: window.__lang('saved_content'),
                },
            ],
            partenaire_cloe_trainer: [
                {
                    url: '/dashboard',
                    icon: ['fal', 'list-ul'],
                    label: window.__lang('dashboard'),
                },
                ...usersList,
                reportingLinks,
            ],
            hrmanager: [
                {
                    url: '/dashboard',
                    icon: ['fal', 'list-ul'],
                    label: window.__lang('dashboard'),
                },
                {
                    icon: ['fal', 'list-alt'],
                    label: ' Content',
                    items:
                        trainerContentLinks.length == 0
                            ? null
                            : trainerContentLinks,
                    url:
                        trainerContentLinks.length == 0
                            ? '/admin/search'
                            : null,
                },
                {
                    url: '/admin/questionnaires',
                    icon: ['fal', 'clipboard-list-check'],
                    label: 'QUESTIONNAIRES',
                },
                {
                    icon: ['fal', 'users'],
                    label: window.__lang('users'),
                    items: usersLinks,
                    // items: [
                    //     { url: '/admin/users', label: window.__lang('users') },
                    //     {
                    //         url: '/admin/user-groups',
                    //         linkProps: { defaultPage: '1_2' },
                    //         label: window.__lang('user_groups'),
                    //     },
                    // ],
                },
                // {
                //     url: '/admin/report',
                //     icon: ['fal', 'chart-pie-alt'],
                //     label: window.__lang('reporting'),
                // },
                reportingLinks,
                {
                    url: '/admin/search',
                    icon: [],
                    label: window.__lang('search'),
                },
                {
                    url: '/admin/wishlist',
                    icon: ['fas', 'heart'],
                    label: window.__lang('wishlist'),
                },
            ],
            learner: [
                {
                    url: '/dashboard',
                    icon: ['fal', 'list-ul'],
                    label: 'DASHBOARD',
                },

                { url: '/search', icon: ['fal', 'search'], label: 'SEARCH' },
                {
                    url: '/wishlist',
                    icon: ['fas', 'heart'],
                    label: 'WISHLIST',
                },
                {
                    url: '/library',
                    icon: ['fal', 'th-large'],
                    label: 'LIBRARY',
                },
            ],
            administrator: [
                {
                    icon: ['fal', 'chalkboard-teacher'],
                    label: ' Courses',
                    items: [
                        { url: '/admin/courses', label: 'List Courses' },
                        {
                            url: '/admin/courses/manage',
                            label: 'Create Course',
                        },
                        {
                            url: '/admin/course-bundles',
                            label: 'List Library Bundles',
                        },
                        {
                            url: '/admin/course-bundles/manage',
                            label: 'Create Library Bundle',
                        },
                        {
                            url: '/admin/learning-pathways',
                            label: 'List Learning Pathways',
                        },
                        {
                            url: '/admin/learning-pathways/manage',
                            label: 'Create Learning Pathway',
                        },
                    ],
                },
                {
                    url: '/admin/modules',
                    icon: ['fal', 'money-check-edit'],
                    label: 'Modules',
                },
                {
                    url: '/admin/questionnaires',
                    icon: ['fal', 'clipboard-list-check'],
                    label: 'Questionnaires',
                },
                {
                    url: '/admin/activities',
                    icon: ['fal', 'user-chart'],
                    label: 'Activities',
                },
                {
                    url: '/admin/feedback',
                    icon: ['fal', 'user-chart'],
                    label: 'Feedback',
                },
                // { url: '/admin/help', icon: ['fal', 'info-square'], label: 'HELP' },
                {
                    url: '/admin/sites',
                    icon: ['fal', 'window-alt'],
                    label: 'Sites',
                    display: permissions?.includes('see-sites')
                        ? 'inline'
                        : 'none',
                },
                {
                    icon: ['fal', 'cog'],
                    label: ' Configuration',
                    items: [
                        {
                            url: '/admin/language-level-types',
                            icon: ['fal', 'cog'],
                            label: 'Levels',
                        },
                        {
                            url: '/admin/learning-types',
                            icon: ['fal', 'cog'],
                            label: 'Skills',
                        },
                        {
                            url: '/admin/learning-focuses',
                            icon: ['fal', 'cog'],
                            label: 'Learning Focuses',
                        },
                        {
                            url: '/admin/interface-languages',
                            icon: ['fal', 'cog'],
                            label: 'Interface Languages',
                        },
                        {
                            url: '/admin/content-languages',
                            icon: ['fal', 'cog'],
                            label: 'Content Languages',
                        },
                        {
                            url: '/admin/picture-db',
                            icon: ['fal', 'cog'],
                            label: 'Picture DB',
                        },
                        {
                            url: '/admin/scorm-cursomers',
                            // icon: ['fal', 'cog'],
                            label: 'Scorm Customers',
                        },
                        {
                            url: '/admin/FAQS-management',
                            label: 'Frequently Asked Questions',
                        },
                    ],
                },
                {
                    // url: '/admin/contacts',
                    icon: ['fal', 'envelope'],
                    label: ' Contacts',
                    items: [
                        {
                            url: '/admin/contacts',
                            icon: ['fal', 'users'],
                            label: 'Contacts',
                        },
                        {
                            url: '/admin/contacts/group',
                            icon: ['fal', 'users'],
                            label: 'Contact Groups',
                        },
                    ],
                },
                {
                    icon: ['fal', 'users'],
                    label: ' Users',
                    items: usersLinks,
                    // items: [
                    //     {
                    //         url: '/admin/users',
                    //         icon: ['fal', 'users'],
                    //         label: 'List Users',
                    //     },
                    //     {
                    //         url: '/admin/users/manage',
                    //         icon: ['fal', 'users'],
                    //         label: 'Create User',
                    //     },
                    //     {
                    //         url: '/admin/user-groups',
                    //         icon: ['fal', 'users'],
                    //         label: 'List User Groups',
                    //     },
                    //     {
                    //         url: '/admin/user-groups/manage',
                    //         icon: ['fal', 'users'],
                    //         label: 'Create User Group',
                    //     },
                    //     {
                    //         url: '/admin/users/import',
                    //         icon: ['fal', 'users'],
                    //         label: 'Import CSV',
                    //     },
                    // ],
                },
                {
                    url: '/admin/shared-files',
                    icon: ['fal', 'folder-plus'],
                    label: 'Shared Files',
                },
            ],
        };
    };

    return links;
};
export default getLinks;

const links = permissions => {
    return {
        trainer: [
            {
                url: '/dashboard',
                icon: ['fal', 'list-ul'],
                label: window.__lang('dashboard'),
            },
            {
                icon: ['fal', 'list-alt'],
                label: window.__lang('content'),
                items: [
                    {
                        url: '/admin/ExploreAndAssign',
                        label: window.__lang('explore_and_assign'),
                        icon: ['fal', 'list-alt'],
                    },
                    {
                        url: '/admin/build/assign',
                        label: window.__lang('create_and_assign'),
                        icon: ['fal', 'list-alt'],
                    },
                ],
                // items: [
                //     {
                //         // url: '/trainer/create-assign'
                //         //  label: 'Create & Assign',
                //         label: ' Content Management',
                //         icon: ['fal', 'list-alt'],
                //         items: [
                //             { url: '/trainer/activities', label: 'Activities' },
                //             { url: '/trainer/modules', label: 'Modules' },
                //             { url: '/trainer/courses', label: 'Courses' },
                //             {
                //                 url: '/trainer/pathways',
                //                 label: 'Learning Pathways',
                //             },
                //             {
                //                 url: '/trainer/coursebundles',
                //                 label: 'Course Bundles',
                //             },
                //         ],
                //     },

                //     // {
                //     //     // url: '/trainer/create-assign'
                //     //     //  label: 'Create & Assign',
                //     //     label: 'Authored Trainer Content',
                //     //      icon:['fal', 'list-alt'],
                //     //      items:[
                //     //     {url:'/trainer/authored-activities' , label:" Activities"},
                //     //     {url:'/trainer/authored-module' , label:" Modules"},
                //     //     {url:'/trainer/authored-courses' , label:" Courses"},
                //     //     {url:'/trainer/authored-pathways' , label:"Learning Pathways"},
                //     //     {url:'/trainer/authored-coursebundles' , label:" Course Bundles"},
                //     // ] },
                //     // {

                //     //     label: 'Site Content',
                //     //      icon:['fal', 'list-alt'],
                //     //      items:[
                //     //     {url:'/trainer/site-activities' , label:"Activities"},
                //     //     {url:'/trainer/site-module' , label:" Modules"},
                //     //     {url:'/trainer/site-courses' , label:" Courses"},
                //     //     {url:'/trainer/site-courses' , label:"Learning Pathways"},
                //     //     {url:'/trainer/site-coursebundles' , label:"Course Bundles"},

                //     // ] },
                //     {
                //         label: 'Users',
                //         icon: ['fal', 'list-alt'],
                //         items: [
                //             {
                //                 url: '/trainer/users-content',
                //                 label: 'User content',
                //             },
                //             {
                //                 url: '/trainer/users-groups',
                //                 label: 'User Group Content',
                //             },
                //         ],
                //     },
                //     // {

                //     //     label: 'User Group Content',
                //     //      icon:['fal', 'list-alt'],
                //     //      items:[
                //     //     {url:'/trainer/usergroup-activities' , label:"Learning Pathways"},
                //     //     {url:'/trainer/usergroup-module' , label:"Library Bundles"},

                //     // ] },

                //     {
                //         url: '/trainer/search-assign',
                //         label: 'Assign Content By Module',
                //         icon: ['fal', 'list-alt'],
                //         items: [
                //             {
                //                 url: '/trainer/search-assign',
                //                 label: ' User Groups',
                //             },
                //             { url: '/trainer/search-assign', label: 'users' },
                //         ],
                //     },
                // ],
            },
            {
                icon: ['fal', 'users'],
                label: window.__lang('users'),
                items: [
                    // { url: '/trainer/users', label: 'Users' },
                    // {
                    //     url: '/trainer/users',
                    //     linkProps: { defaultPage: '1_2' },
                    //     label: 'User Groups',
                    // },
                    { url: '/admin/users', label: window.__lang('users') },
                    {
                        url: '/admin/user-groups',
                        linkProps: { defaultPage: '1_2' },
                        label: window.__lang('user_groups'),
                    },
                ],
            },
            {
                url: '/admin/report',
                icon: ['fal', 'chart-pie-alt'],
                label: window.__lang('reporting'),
                // items: [
                //     {
                //         url: '/admin/report',
                //         icon: ['fal', 'users'],
                //         label: 'Users Report',
                //     },
                //     {
                //         url: '/admin/timereport',
                //         icon: ['fal', 'users'],
                //         label: 'Time Report',
                //     },
                // ],
            },

            {
                url: '/admin/search',
                icon: [],
                label: window.__lang('search'),
            },
            {
                url: '/admin/wishlist',
                icon: ['fas', 'heart'],
                label: window.__lang('wishlist'),
            },
            // {
            //     url: '/trainer/user-admin',
            //     icon: ['fal', 'user'],
            //     label: 'User Admin',
            // },
            // {
            //     url: '/trainer/school-admin',
            //     icon: ['fal', 'graduation-cap'],
            //     label: 'School Admin',
            // },
        ],
        // trainer: [
        //     {
        //         icon: ['fal', 'chalkboard-teacher'],
        //         label: 'Courses',
        //         items: [
        //             { url: '/admin/courses', label: 'List Courses' },
        //             { url: '/trainer/courses/manage', label: 'Create Course' },
        //             {
        //                 url: '/admin/course-bundles',
        //                 label: 'List Library Bundles',
        //             },
        //             {
        //                 url: '/admin/course-bundles/manage',
        //                 label: 'Create Library Bundle',
        //             },
        //             {
        //                 url: '/admin/learning-pathways',
        //                 label: 'List Learning Pathways',
        //             },
        //             {
        //                 url: '/admin/learning-pathways/manage',
        //                 label: 'Create Learning Pathway',
        //             },
        //         ],
        //     },
        //     {
        //         url: '/admin/modules',
        //         icon: ['fal', 'money-check-edit'],
        //         label: 'MODULES',
        //     },
        //     {
        //         url: '/admin/activities',
        //         icon: ['fal', 'user-chart'],
        //         label: 'Activities',
        //     },
        //     {
        //         icon: ['fal', 'users'],
        //         label: 'Users',
        //         items: [
        //             {
        //                 url: '/admin/users',
        //                 icon: ['fal', 'users'],
        //                 label: 'List Users',
        //             },
        //             {
        //                 url: '/admin/user-groups',
        //                 icon: ['fal', 'users'],
        //                 label: 'List User Groups',
        //             },
        //         ],
        //     },
        //     {
        //         icon: ['fal', 'user-chart'],
        //         label: 'Global Reporting',
        //         items: [
        //             {
        //                 url: '/admin/report',
        //                 icon: ['fal', 'users'],
        //                 label: 'Users Report',
        //             },
        //             {
        //                 url: '/admin/timereport',
        //                 icon: ['fal', 'users'],
        //                 label: 'Time Report',
        //             },
        //         ],
        //     },
        //     {
        //         url: '/admin/search',
        //         icon: [],
        //         label: 'Search',
        //     },
        //     {
        //         url: '/admin/wishlist',
        //         icon: [],
        //         label: 'WishList',
        //     },
        // ],
        learner: [
            {
                url: '/dashboard',
                icon: ['fal', 'list-ul'],
                label: 'DASHBOARD',
            },

            { url: '/search', icon: ['fal', 'search'], label: 'SEARCH' },
            {
                url: '/wishlist',
                icon: ['fas', 'heart'],
                label: 'WISHLIST',
            },
            {
                url: '/library',
                icon: ['fal', 'th-large'],
                label: 'LIBRARY',
            },
        ],
        administrator: [
            {
                icon: ['fal', 'chalkboard-teacher'],
                label: ' Courses',
                items: [
                    { url: '/admin/courses', label: 'List Courses' },
                    {
                        url: '/admin/courses/manage',
                        label: 'Create Course',
                    },
                    {
                        url: '/admin/course-bundles',
                        label: 'List Library Bundles',
                    },
                    {
                        url: '/admin/course-bundles/manage',
                        label: 'Create Library Bundle',
                    },
                    {
                        url: '/admin/learning-pathways',
                        label: 'List Learning Pathways',
                    },
                    {
                        url: '/admin/learning-pathways/manage',
                        label: 'Create Learning Pathway',
                    },
                ],
            },
            {
                url: '/admin/modules',
                icon: ['fal', 'money-check-edit'],
                label: 'MODULES',
            },
            // {
            //     url: '/admin/questionnaires',
            //     icon: ['fal', 'clipboard-list-check'],
            //     label: 'QUESTIONNAIRES',
            // },
            {
                url: '/admin/activities',
                icon: ['fal', 'user-chart'],
                label: 'Activities',
            },
            // { url: '/admin/help', icon: ['fal', 'info-square'], label: 'HELP' },
            {
                url: '/admin/sites',
                icon: ['fal', 'window-alt'],
                label: 'SITES',
                display: permissions?.includes('see-sites') ? 'inline' : 'none',
            },
            {
                icon: ['fal', 'cog'],
                label: ' CONFIGURATION',
                items: [
                    {
                        url: '/admin/language-level-types',
                        icon: ['fal', 'cog'],
                        label: 'Levels',
                    },
                    {
                        url: '/admin/learning-types',
                        icon: ['fal', 'cog'],
                        label: 'Skills',
                    },
                    {
                        url: '/admin/learning-focuses',
                        icon: ['fal', 'cog'],
                        label: 'Learning Focuses',
                    },
                    {
                        url: '/admin/interface-languages',
                        icon: ['fal', 'cog'],
                        label: 'Interface Languages',
                    },
                    {
                        url: '/admin/content-languages',
                        icon: ['fal', 'cog'],
                        label: 'Content Languages',
                    },
                    {
                        url: '/admin/picture-db',
                        icon: ['fal', 'cog'],
                        label: 'Picture DB',
                    },
                ],
            },
            {
                // url: '/admin/contacts',
                icon: ['fal', 'envelope'],
                label: ' CONTACTS',
                items: [
                    {
                        url: '/admin/contacts',
                        icon: ['fal', 'users'],
                        label: 'Contacts',
                    },
                    {
                        url: '/admin/contacts/group',
                        icon: ['fal', 'users'],
                        label: 'Contact Groups',
                    },
                ],
            },
            {
                icon: ['fal', 'users'],
                label: ' Users',
                items: [
                    {
                        url: '/admin/users',
                        icon: ['fal', 'users'],
                        label: 'List Users',
                    },
                    {
                        url: '/admin/users/manage',
                        icon: ['fal', 'users'],
                        label: 'Create User',
                    },
                    {
                        url: '/admin/user-groups',
                        icon: ['fal', 'users'],
                        label: 'List User Groups',
                    },
                    {
                        url: '/admin/user-groups/manage',
                        icon: ['fal', 'users'],
                        label: 'Create User Group',
                    },
                    {
                        url: '/admin/users/import',
                        icon: ['fal', 'users'],
                        label: 'Import CSV',
                    },
                ],
            },
            {
                url: '/admin/shared-files',
                icon: ['fal', 'folder-plus'],
                label: 'SHARED FILES',
            },
        ],
    };
};

// export default links;
